import React, {FC} from 'react';
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import {facebookShare, linkedinShare, twitterShare} from '../../../assets/svg-icons';

import './styles.scss';

const ShareButtons = () => {
  const pageUrl = global && global.window && global.window.location && global.window.location.href;
  return pageUrl ? (
    <div className="share-btns-wrapper">
      <LinkedinShareButton url={pageUrl} className="linkedin-share">
        {linkedinShare}
      </LinkedinShareButton>
      <FacebookShareButton url={pageUrl} className="facebook-share">
        {facebookShare}
      </FacebookShareButton>

      <TwitterShareButton url={pageUrl} className="twitter-share">
        {twitterShare}
      </TwitterShareButton>
    </div>
  ) : (
    <></>
  );
};
export default ShareButtons;
