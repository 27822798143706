import React, {FC} from 'react';
import {Link} from 'gatsby';
import Benefit from '../benefits';
import ShareButtons from '../share-buttons';
import './styles.scss';

interface VacancyProps {
  intro: any;
  division: string;
  vacancy: string;
  // location: string;
  workType: string;
  jobResponsibilities: any;
  qualifications: any;
}
const Vacancy: FC<VacancyProps> = ({
  intro,
  division,
  vacancy,
  workType,
  // location,
  jobResponsibilities,

  qualifications,
}) => {
  return (
    <div className="vacancy-page">
      <div className="badge-title">{division}</div>
      <h1 className="page-title">{vacancy}</h1>
      {/* <p className="text-center">{location}</p> */}
      <div className="share-vacancy">
        <ShareButtons />
      </div>
      <div className="vacancy-container mt-4">
        <div> {intro}</div>
        <div className="page-subtitle  mt-4">Job Details</div>
        <div>
          <p>Work type: {workType}</p>
        </div>

        <div>
          <div className="page-subtitle mt-4">Job responsibilities</div>
          <div>{jobResponsibilities}</div>
        </div>

        <div>
          <div className="page-subtitle mt-4">Required qualifications</div>
          <div>{qualifications}</div>
        </div>

        <div className="mt-4">
          <p>
            All interested candidates are welcome to apply by sending their CVs to{' '}
            <a href="mailto:hr@hexact.io" target='_blank' rel='noopener'>hr@hexact.io</a>
          </p>

          <Link to="/careers" state={{vacancy: true}} className="openings">
            {' '}
            &lt; See all openings
          </Link>
        </div>
      </div>
      <Benefit />
    </div>
  );
};

export default Vacancy;
